<template>
    <div class="cardContainer">
        <div class="imgCon" v-for="card in cards" :key="card">
            <img :src="card.img" alt="" class="colorImg">
            <div class="tickOptions">
                <div v-for="index in card.index" :key="index" @click="checkCards(index)" style="position:relative"><div v-show="inFound(index)" class="check"></div></div>
            </div>
        </div>
        <game-won :gameOver="gameOver" @restart="restartGame" @goBack="goBack"></game-won>
    </div>
</template>

<script>
import GameWon from './GameWon.vue'

export default {
    name: 'DifferenceGame',
    props: ["cards"],
    components:{
        GameWon
    },
    data(){
        return{
            difference: [],
            found: [],
            gameOver: false,
        }
    },
    methods:{
        checkCards(i){
            if(this.difference.includes(i)){
                for(var x in this.difference) {
                    if(this.difference[x] == i) {
                        delete this.difference[x];
                        this.found.push(i);
                    }
                }
            }
            if(Object.keys(this.difference) < 1){
                this.gameOver = true;
            
            }
        },
        restartGame(){
            this.gameOver = false;
            this.difference = JSON.parse(JSON.stringify(this.cards[0].difference));
            this.found = [];
        },
        inFound(i){
            if(this.found.includes(i)){
                return true;
            }
            return false;
        },
        goBack(){
            this.$emit("goBack")
        }
    },
    created(){
        this.difference = JSON.parse(JSON.stringify(this.cards[0].difference))
    }
}
</script>

<style scoped>
.cardContainer{
    justify-content: space-around;
    padding: 20px;
    gap:20px;
    overflow: hidden;
}
.imgCon{
    position: relative;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.colorImg{
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
}
.tickOptions{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr    ;
    gap:1px;
}
.tickOptions div{
    display:flex;
    align-items: center;
    justify-content: center;
    /* background: rgba(0, 0, 0, 0.1); */
}
.check{
    position: absolute;
    left:0;
    border: 4px solid white;
    border-radius: 50%;
    width: 180px;
    height: 180px;
}
</style>