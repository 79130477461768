<template>
    <div class="level" :style="'background-image:url('+bgImg+') ;background-size:150px auto;background-position:top;background-color:'+bgColor+';left:'+left+'%'" :class="active?'active':''" @click="startGame = true">
        {{name}}
    </div>
    <memory-game v-if="startGame && type == 'memory'" :cards="cards" class="game" @goBack="goBack"></memory-game>
    <color-game v-if="startGame && type == 'color'" :cards="cards" class="game" @goBack="goBack"></color-game>
    <difference-game v-if="startGame && type == 'difference'" :cards="cards" class="game" @goBack="goBack"></difference-game>
    <div class="backButton" @click="startGame = false" v-show="startGame">Ga terug</div>
</template>

<script>
import MemoryGame from './MemoryGame.vue'
import ColorGame from './ColorGame.vue'
import DifferenceGame from './DifferenceGame.vue'

export default {
    name: "LevelButton",
    props: ["name", "bgImg", "bgColor", "active","amount", "index", "cards", "type"],
    components:{
        MemoryGame,
        ColorGame,
        DifferenceGame
    },
    data(){
        return{
            startGame: false
        }
    },
    computed:{
        left(){
            if(this.active){
                if(this.amount == 2){
                    if(this.index == 0){
                        return 40;
                    }
                    return 60;
                }
                if(this.amount == 3){
                    if(this.index == 0){
                        return 33;
                    }
                    if(this.index == 2){
                        return 66;
                    }
                    return 50;
                }
                if(this.amount == 4){
                    if(this.index == 0){
                        return 20;
                    }
                    if(this.index == 1){
                        return 40;
                    }
                    if(this.index == 2){
                        return 60;
                    }
                    if(this.index == 3){
                        return 80;
                    }
                    return 50;
                }
            }
        }
    },
    methods:{
        goBack(){
            this.startGame = false;
            this.$emit("goBack")
        }
    }
}
</script>
<style scoped>

.level{
    color:white;
    position: fixed;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 20px;
    top:-20%;
    padding:0px;
    transition: all 0.2s ease;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 250px;
    opacity: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}
.active{
    opacity:1;
    top:40%;
}

.backButton{
    position: fixed;
    left: 20px;
    bottom: 20px;
    background: #7accdb;
    color:white;
    padding: 20px;
    font-size: 30px;
    border-radius: 30px;
    z-index: 99999999999;
}
.game{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    background: #EEEFE7;
    z-index: 9999999999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>